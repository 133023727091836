<template>
  <vs-popup
    classContent="popup-example"
    :title="lang.apiCalls.edit.popUpTitle[languageSelected]"
    :active.sync="activePopup"
  >
    <div>
      <span>Url</span>
      <vs-input
        class="mr-3 mt-2 w-full mb-base"
        v-model="newUrl"
        placeholder="https://example.com/integration"
        :disabled="saving || (integration && integration.type === 'cyberbank')"
      />
      <vs-divider />
      <span>{{ lang.apiCalls.add.headers.title[languageSelected] }}</span>
      <vs-table
        :data="newHeaders"
        sorted
        class="mt-2"
        v-model="selected"
        :no-data-text="lang.apiCalls.add.headers.empty[languageSelected]"
        @selected="handleSelected"
      >
        <template slot="thead">
          <vs-th sort-key="header">{{
            lang.apiCalls.add.headers.header[languageSelected]
          }}</vs-th>
          <vs-th sort-key="value">{{
            lang.apiCalls.add.headers.value[languageSelected]
          }}</vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in data"
            class="m-0 p-0"
          >
            <vs-td :data="data[indextr].header">{{
              data[indextr].header
            }}</vs-td>
            <vs-td :data="data[indextr].value">{{ data[indextr].value }}</vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="mb-4 mr-1 ml-1">
        <div class="vx-row mt-6">
          <div class="vx-col md:w-1/2 w-full">
            <vs-input
              class="w-full"
              v-model="header"
              :label="lang.apiCalls.add.headers.header[languageSelected]"
              @keyup.enter="addHeader"
              :disabled="saving"
            ></vs-input>
          </div>
          <div class="vx-col md:w-1/2 w-full">
            <vs-input
              class="w-full"
              v-model="value"
              :label="lang.apiCalls.add.headers.value[languageSelected]"
              @keyup.enter="addHeader"
              :disabled="saving"
            ></vs-input>
          </div>
        </div>
      </div>

      <vs-button
        type="filled"
        class="float-right mt-4 mb-base"
        icon-pack="feather"
        icon="icon-plus"
        radius
        color="primary"
        @click="addHeader"
        :disabled="saving"
      ></vs-button>
      <vs-divider />
      <vs-button
        class="float-right"
        icon-pack="feather"
        icon="icon-save"
        @click="save"
        :disabled="saving"
      >
        {{ lang.apiCalls.edit.saveText[languageSelected] }}
      </vs-button>
      <vs-button
        type="flat"
        class="float-right mr-4"
        icon-pack="feather"
        icon="icon-x"
        color="danger"
        @click="cancel"
        :disabled="saving"
      >
        {{ lang.apiCalls.edit.cancelText[languageSelected] }}
      </vs-button>
    </div>
  </vs-popup>
</template>

<script>
import BotMakerService from '../../../services/botMaker.service'

import { mapMutations, mapState, mapGetters } from 'vuex'

export default {
  name: 'EditIntegration',
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
  },
  props: {
    integration: Object
  },
  data() {
    return {
      activePopup: false,
      saving: false,

      newUrl: '',
      newMethod: '',
      newHeaders: [],
      header: '',
      value: '',
      selected: null
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'apiCalls'])
  },
  watch: {
    integration(newVal) {
      this.updateFields(newVal)
    }
  },
  methods: {
    ...mapMutations('botMaker', ['UPDATE_API_CALL']),
    navigateToAddIntegration() {
      this.$refs.addIntegration.openPopup()
    },
    openPopup() {
      this.activePopup = true
    },
    updateFields(newVal) {
      this.newUrl = newVal.url
      this.newMethod = newVal.method
      this.newHeaders = Object.keys(newVal.headers).map(key => ({
        header: key,
        value: newVal.headers[key]
      }))
    },
    isValidUrl(text) {
      if (/^http:\/\/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(text)) {
        return true
      }
      return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gim.test(
        text
      )
    },
    isHttps(text) {
      if (/^http:\/\/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}/.test(text)) {
        return true
      }
      return /^https:\/\/.*/i.test(text)
    },
    handleSelected(tr) {
      let index = null
      this.newHeaders.some((element, i) => {
        if (element.header === tr.header && element.value === tr.value) {
          index = i
        }
        return index !== null
      })
      if (index !== null) {
        this.newHeaders.splice(index, 1)
      }
    },
    addHeader() {
      if (
        !this.header ||
        this.header.trim().length === 0 ||
        !this.value ||
        this.value.trim().length === 0
      ) {
        return
      }
      this.newHeaders.push({
        header: this.header,
        value: this.value
      })
      this.header = ''
      this.value = ''
    },
    cancel() {
      this.activePopup = false
    },
    async save() {
      if (!this.isValidUrl(this.newUrl)) {
        return this.$vs.notify({
          title: this.lang.apiCalls.validationErrors.url.title[
            this.languageSelected
          ],
          text: this.lang.apiCalls.validationErrors.url.text[
            this.languageSelected
          ],
          color: 'warning',
          position: 'top-right'
        })
      }
      if (!this.isHttps(this.newUrl)) {
        return this.$vs.notify({
          title: this.lang.apiCalls.validationErrors.https.title[
            this.languageSelected
          ],
          text: this.lang.apiCalls.validationErrors.https.text[
            this.languageSelected
          ],
          color: 'warning',
          position: 'top-right'
        })
      }
      this.saving = true
      try {
        const apiCallHeaders = {}
        this.newHeaders.forEach(element => {
          apiCallHeaders[element.header] = element.value
        })
        const apiCall = {
          url: this.newUrl,
          method: 'post',
          headers: apiCallHeaders
        }
        const result = await BotMakerService.updateApiCall(
          this.integration._id,
          apiCall
        )
        this.$vs.notify({
          title: this.lang.apiCalls.edit.success.title[this.languageSelected],
          text: this.lang.apiCalls.edit.success.text[this.languageSelected],
          color: 'success',
          position: 'top-right'
        })
        this.UPDATE_API_CALL(result.data)
        this.cancel()
      } catch (error) {
        this.$vs.notify({
          title: this.lang.apiCalls.edit.error.title[this.languageSelected],
          text: this.lang.apiCalls.edit.error.text[this.languageSelected],
          color: 'warning',
          position: 'top-right'
        })
      }
      this.saving = false
    }
  }
}
</script>
