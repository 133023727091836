<template>
  <vs-row>
    <vs-col class="sm:w-1/1 md:w-1/1 lg:w-1/1 xl:w-1/1">
      <IntegrationsList :read-only="isReadOnly"/>
    </vs-col>
  </vs-row>
</template>

<script>
import IntegrationsList from './IntegrationsList.vue'

import { mapMutations, mapActions, mapState, mapGetters } from 'vuex'
import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'Integrations',
  components: {
    IntegrationsList
  },
  mixins: [staffManagementMixin],
  data() {
    return {}
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['bot', 'apiCalls']),
    isReadOnly() {
      return !this.isAuthAux('konecta.bot_maker.edit_bot', true)
    }
  },
  methods: {
    ...mapMutations('botMaker', ['START_STORE', 'SET_BOT_ID']),
    ...mapActions('botMaker', [
      'GET_API_CALLS',
      'GET_BOT',
      'GET_ACTIVE_VERSION',
      'GET_BOT_VERSIONS'
    ])
  },
  async mounted() {
    const botId = this.$route.query.bot
    const serviceId = this.$route.query.service

    if (!this.bot || this.bot.id !== botId) {
      this.$vs.loading()
      this.START_STORE()
      this.SET_BOT_ID(botId)
      try {
        await this.GET_BOT(botId)

        if (this.bot.legacy) {
          this.$notify.warning(
            this.lang.botMaker.legacyBot[this.languageSelected]
          )
          this.$router.push('/bot-maker/bots')
        } else {
          await this.GET_ACTIVE_VERSION([serviceId, this.$route.query.version])
          await this.GET_BOT_VERSIONS([serviceId, this.$route.query.version])
        }
        this.finishedLoading = true
      } catch (error) {
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.error[this.languageSelected],
          color: 'danger'
        })
        this.$log.error(error)
        return await Promise.reject(error)
      }
      this.$vs.loading.close()
    }

    await this.GET_API_CALLS(this.bot.activeVersion)
  },
  watch: {
    async 'bot.activeVersion'() {
      await this.GET_API_CALLS(this.bot.activeVersion)
    }
  }
}
</script>
